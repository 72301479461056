'use client'

import {usePathname, useSearchParams} from 'next/navigation'
import * as LIB from './_lib'
import {MenuItem} from '@/services/menus.service'

export default function Header({
  primaryMenu,
  secondaryMenu,
}: {
  primaryMenu?: MenuItem[]
  secondaryMenu?: MenuItem[]
}) {
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const viewPort = searchParams?.get('viewport')
  const isMobile = viewPort === 'mobile'

  const withoutHeader =
    pathname === '/auth/forget-password' ||
    pathname === '/auth/fast-login/verify' ||
    pathname === '/auth/register-verify-code' ||
    pathname?.includes('filter') ||
    pathname?.includes('current-address') ||
    pathname?.includes('new-phone-verification') ||
    pathname?.includes('/network-error') ||
    pathname?.includes('404') ||
    pathname === '/429' ||
    (pathname === '/payment' && !pathname.includes('/payment/success')) ||
    pathname?.includes('payment-failed')

  const showHeaderOnMobile =
    pathname?.includes('cart') ||
    pathname?.includes('/auth/fast-login') ||
    pathname?.includes('my-account/favorites') ||
    pathname === '/my-account/user-info' ||
    pathname?.includes('my-account/orders')

  return withoutHeader ? (
    <></>
  ) : isMobile ? (
    showHeaderOnMobile ? (
      <></>
    ) : (
      <LIB.C.HeaderMobile primaryMenuItems={primaryMenu} secondaryMenuItems={secondaryMenu} />
    )
  ) : (
    <LIB.C.HeaderDesktop primaryMenuItems={primaryMenu} />
  )
}
