'use client'

import {useTranslate} from '@/hooks/useTranslate'
import Link from 'next/link'
import UI_Button from '../ui-kit/button/Button'
import UI_Typography from '../ui-kit/typography/UI_Typography'
import styles from './styles.module.scss'
import {useFooter} from './useFooter'
import {Container} from '../container/Container'

const Footer = () => {
  const {get, on} = useFooter()

  const footerLinksTitle = useTranslate('FOOTER_LINKS_TITLE')
  const footerTextOne = useTranslate('FOOTER_TEXT_ONE')
  const footerCopyright = useTranslate('FOOTER_COPYRIGHT', {year: get.currentYear})

  const bottomLinks = get.bottomLinks.map((link) => ({
    ...link,
    nameTranslated: useTranslate(link.name),
  }))

  return !get.shouldNotRenderFooter ? (
    <footer
      className={styles['footer']}
      style={(get.isMobile && on.handleMobileExtraStyle()) || {}}
    >
      <div className={styles['links']}>
        <UI_Typography variant={get.isMobile ? 'Medium/Med18' : 'Medium/Med22'}>
          {footerLinksTitle} :
        </UI_Typography>
        <div className={styles['link_wrapper']}>
          {bottomLinks.map((link) => (
            <div className={styles['link']} key={link.name}>
              <Link href={link.href}>
                <UI_Button variant='ghost'>
                  <UI_Typography
                    variant={get.isMobile ? 'Regular/Reg10' : 'Regular/Reg12'}
                    color='var(--secondary-secondary)'
                  >
                    {link.nameTranslated}
                  </UI_Typography>
                </UI_Button>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <UI_Typography
        variant={get.isMobile ? 'Medium/Med18' : 'Medium/Med22'}
        className={styles['company']}
        component='p'
      >
        {footerTextOne}
      </UI_Typography>

      <UI_Typography
        variant={get.isMobile ? 'Medium/Med16' : 'Medium/Med18'}
        className={styles['company']}
        component='p'
      >
        Destek: <a href='tel:08504802444'>0850 480 2444</a>
      </UI_Typography>

      <Container>
        <UI_Typography
          variant={get.isMobile ? 'Regular/Reg12' : 'Regular/Reg14'}
          color='var(--secondary-secondary)'
          className={styles['description']}
          component='p'
        >
          Tofisa olarak, tesettür giyim alanında zarafeti ve şıklığı bir araya getiren ürünlerimizi
          sizlerle buluşturuyoruz. Kaliteli kumaşlar ve modern tasarımlar ile hem günlük hayatta hem
          de özel günlerde tarzınızı yansıtabileceğiniz seçenekler sunuyoruz. Modaya uygun, rahat ve
          özgün parçalarla, tesettür giyimde fark yaratmak için buradayız.
        </UI_Typography>
      </Container>
      <UI_Typography
        variant={get.isMobile ? 'Regular/Reg10' : 'Regular/Reg12'}
        color='var(--secondary-secondary-light)'
        className={styles['rights']}
        component='p'
      >
        {footerCopyright}
      </UI_Typography>
    </footer>
  ) : null
}

export default Footer
